require('./shared/layout');

import $ from 'jquery';
import Axios from 'axios';
import { parseQueryParams } from './shared/utils';

const axios = Axios.create({ withCredentials: true });
$(document).ready(function () {
  var searchParams = parseQueryParams(window.location.search || '');

  var formError;
  var loginSuccess;
  var processing;

  var activateExplicitSignInForm = function () {
    $('#form_auth').removeClass('inactive');
    if (this.dataset.placeholder) {
      this.setAttribute('placeholder', this.dataset.placeholder);
    }
  };

  $('#input_uid').focus(activateExplicitSignInForm);

  var isSignupPage = window.location.href.indexOf('/signup') > -1;

  var sleep = function (ms) {
    return new Promise((res) => {
      setTimeout(res, ms);
    });
  };

  var setFormError = function (error) {
    formError = error;
    var el = $('#form_error');
    if (error == null) {
      el.text('');
      el.hide();
    } else {
      el.text(error.message || error);
      el.show();
    }
  };

  var setFormSuccess = function () {
    loginSuccess = true;
  };

  var setProcessing = function (state) {
    processing = state;
    var btn = $('#form_auth .cta_wrap .cta_btn');
    if (processing === true) {
      btn.addClass('processing');
    } else {
      btn.removeClass('processing');
    }

    btn.attr('disabled', processing);
    var inputs = $('#form_auth input');
    inputs.attr('disabled', processing);
  };

  var activateForm = function () {
    $('.form_wrapper').addClass('activated');
  };

  var onSignedin = function () {
    $('.title_main').text('Signed in');
    $('.titles_wrap .desc').text('Checking redirections ..');

    var redirecUri = searchParams.redirect;
    if (redirecUri) {
      redirecUri = decodeURI(searchParams.redirect);
    } else {
      redirecUri = process.env.LOGIN_REDIRECT_URL + window.location.search;
    }

    window.location.href = redirecUri;
    $('#form_auth').hide();
    $('.social-connect-wrap').hide();
  };

  var onSiginFailed = function (err) {
    setFormError(err);
    $('#input_password').val('');
  };

  function onFormSubmit(ev) {
    ev.preventDefault();
    if (processing) return;

    var name = $('#input_name');
    var email = $('#input_uid');
    var pass = $('#input_password');
    var promoConsent = $('#promosConsent');

    var nameValue = name.val();
    var emailValue = email.val();
    var passValue = pass.val();

    if (!emailValue || !passValue) {
      return setFormError({
        message: 'Please type in your email and password',
      });
    }

    var payload = {
      email: emailValue,
      // password: passValue,
      passphrase: passValue,
      promosConsent: promoConsent.prop('checked'),
    };

    if (isSignupPage) {
      if (!nameValue) {
        return setFormError({
          message: 'Please type in your name',
        });
      }

      payload.name = nameValue;
    } else {
      payload.scope = ['root'];
    }

    setFormError(null);
    setProcessing(true);

    setTimeout(() => {
      signin(payload).then(
        function (res) {
          console.log('res', res && res.data);
          setFormSuccess(true);
          onSignedin();

          setProcessing(false);
        },
        function (e) {
          var err = e && e.response && e.response.data;
          onSiginFailed(err);

          setProcessing(false);
        },
      );
    }, 1000);
  }

  function checkSession() {
    // return activateForm();
    var apiurl = process.env.AUTH_API_BASE + '/auth/access_token';
    axios.get(apiurl).then(onSignedin, function (e) {
      console.log('not logged in', e.response);
      activateForm();

      if (searchParams.email) {
        activateExplicitSignInForm();
      }
    });
  }

  function checkAutoFill() {
    if (searchParams.email) {
      $('#input_uid').val(searchParams.email);
    }
  }

  function prependSearchParams() {
    $('[data-auth-link]').each((i, a) => {
      var hrefurl = $(a).prop('href');
      if (hrefurl.indexOf('?') > -1) {
        hrefurl += '&';
      } else {
        hrefurl += '?';
      }

      var searchParamKeys = Object.keys(searchParams);
      if (searchParamKeys.length) {
        searchParamKeys.forEach((prop) => {
          hrefurl += prop + '=' + searchParams[prop];
        });
      }

      a.href = hrefurl;
    });
  }

  function signin(payload) {
    return new Promise(function (res, rej) {
      payload = Object.assign(payload);

      var apiPathname = '/auth/signin';
      if (isSignupPage) {
        apiPathname = '/auth/signup';
      }

      // return console.log('signinURL', signinURL);
      var apiUri = process.env.AUTH_API_BASE + apiPathname;
      axios.post(apiUri, payload).then(res, rej);
    });
  }

  // @signin
  var onConnectAppClick = function () {
    var appName = this.dataset.app;
    var oauthURI =
      process.env.AUTH_API_BASE + '/apps/connect?appName=' + appName;
    // redirect to oauth consent
    if (Object.keys(searchParams).length) {
      let stateParam = btoa(window.location.search);
      oauthURI += '&state=' + stateParam;
    }

    window.location.href = oauthURI;
  };


  $('#form_auth').submit(onFormSubmit);
  $('.connect-btns button').click(onConnectAppClick);

  // check if already logged-in
  checkSession();
  checkAutoFill();
  prependSearchParams();

  if (isSignupPage && window.gtag) {
    // console.log('is signup page', 'window.gtag', window.gtag, 'searchParams', searchParams)
    console.log('gtag-conversion', searchParams.campaign_id)
    var campaign_id = searchParams.campaign_id;
    if (campaign_id === 'adwords') {
      window.gtag('event', 'conversion', { 'send_to': 'AW-10780691895/TbyaCPGf88kDELeb0ZQo' });
    }
  }
});
